<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="invoiceModal" persistent max-width="500">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title class="overline">Faturar Venda</v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="closeInvoiceModal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container fluid>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    outlined
                    v-model="payed.accountId"
                    :items="accounts"
                    item-value="id"
                    item-text="name"
                    label="Conta"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="payed.email"
                    label="Usuário"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="mt-n8">
                  <v-text-field
                    outlined
                    v-model="payed.password"
                    label="Senha"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <v-btn dark color="green" width="100%" @click="saleInvoice">
                    FATURAR
                  </v-btn>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-btn
                    dark
                    color="red"
                    width="100%"
                    @click="closeInvoiceModal"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0" height="140">
          <v-row>
            <v-col cols="3" class="mt-6">
              <v-text-field
                outlined
                v-model="startDate"
                type="date"
                label="Data Inicial"
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="mt-6">
              <v-text-field
                outlined
                v-model="endDate"
                type="date"
                label="Data Final"
              ></v-text-field>
            </v-col>
            <v-col cols="1" />
            <v-col cols="2" class="mt-6">
              <v-radio-group v-model="status" @change="getItems()">
                <v-radio label="Aberto" value="ABERTO"></v-radio>
                <v-radio label="Fechado" value="FECHADO"></v-radio>
                <v-radio label="Faturado" value="FATURADO"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2" class="mt-9">
              <v-btn dark color="green" outlined @click="getItems()">
                BUSCAR
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-row>
          <v-col cols="5">
            <v-btn
              width="95%"
              outlined
              color="#2ca01c"
              to="/base/sale/create"
              dark
              class="mr-4 ml-4 mb-4"
            >
              <v-icon>mdi-plus-thick</v-icon>
              Nova Venda</v-btn
            >
            <v-data-table
              :loading="loading"
              class="elevation-4"
              :headers="headers"
              :items="sales"
              disable-pagination
              hide-default-footer
              @click:row="changeItems($event)"
            >
              <template v-slot:[`item.saleNumber`]="{ item }">
                <v-chip
                  v-if="item.id == sale.id"
                  color="green"
                  dark
                  class="short"
                >
                  {{ item.saleNumber }}
                </v-chip>
                <span v-else> {{ item.saleNumber }}</span>
              </template>

              <template v-slot:[`item.amountLiquid`]="{ item }">
                <span> {{ formatPrice(item.amountLiquid) }}</span>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="7">
            <v-card class="ma-5 mt-0" elevation="4">
              <br />
              <span class="overline text-caption ma-4">Vendedor </span>
              <v-card-subtitle class="mt-n4">{{
                sale && sale.salesman ? sale.salesman.person.corporateName : "-"
              }}</v-card-subtitle>
              <span class="overline text-caption ma-4">Produtos</span>
              <v-data-table
                :loading="loading"
                :headers="productHeaders"
                :items="items"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.totalPrice`]="{ item }">
                  <span> {{ formatPrice(item.totalPrice) }}</span>
                </template>
              </v-data-table>
              <br />
              <br />

              <span class="overline text-caption ma-4">Pagamento</span>
              <hr />
              <br />
              <v-data-table
                :loading="loading"
                :headers="instalmentHeaders"
                :items="instalments"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.amount`]="{ item }">
                  <span> R$ {{ formatPrice(item.amount) }}</span>
                </template>
                <template v-slot:[`item.number`]="{ item }">
                  <span>
                    {{ `${item.frequencyNumber}/${item.frequencyTotal}` }}</span
                  >
                </template>
                <template v-slot:[`item.dueDate`]="{ item }">
                  <span> {{ dateFormat(item.dueDate) }}</span>
                </template>
              </v-data-table>
              <v-btn
                @click="openInvoiceModal(sale)"
                dark
                block
                color="green"
                v-if="sale && sale.status === 'FECHADO'"
                >Faturar</v-btn
              >
              <v-btn
                :to="`/base/sale/${sale.id}/edit`"
                dark
                block
                color="green"
                v-if="sale && sale.status === 'ABERTO'"
                >Concluir Venda</v-btn
              >
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
import moment from "moment";
export default Vue.extend({
  name: "Vendas",
  components: { PageHeader, InternalMenu },
  data: () => ({
    title: "Vendas",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Categorias de Produtos",
            to: "/base/categories",
            permission: "digitech_plus.users.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Clientes",
            to: "/base/customers",
            permission: "immobile_tech.immobile.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Colaboradores",
            to: "/immobile/employees",
            permission: "immobile_tech.immobile.employees.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Vendas",
        disabled: true,
        href: "/base/sales",
      },
    ],
    deleteDialog: false,
    sales: [],
    accounts: [],
    items: [],
    instalments: [],
    search: "",
    loading: false,
    saleId: "",
    invoiceModal: false,
    sale: null,
    startDate: moment().format("yyyy-MM-DD"),
    endDate: moment().format("yyyy-MM-DD"),
    status: "FECHADO",
    payed: {
      accountId: "",
      email: "",
      password: "",
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },

    headers: [
      {
        text: "Nº",
        align: "center",
        value: "saleNumber",
        filtering: true,
      },
      {
        text: "Cliente",
        align: "start",
        value: "customer.person.corporateName",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amountLiquid",
        align: "center",
        filtering: true,
      },
    ],
    productHeaders: [
      {
        text: "Descrição",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "QTD",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Estoque",
        align: "start",
        value: "product.stock",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "totalPrice",
        filtering: true,
      },
    ],
    instalmentHeaders: [
      {
        text: "Nº",
        align: "start",
        value: "number",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        align: "start",
        value: "paymentMethod.title",
        filtering: true,
      },
      {
        text: "Valor R$",
        value: "amount",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    changeItems(item) {
      this.sale = item;
      this.saleId = item.id;
      this.getSaleItems(item.id);
      this.getInstalments(item.id);
    },

    async getSaleItems(saleId) {
      await http.get(`base/sale/${saleId}/items`).then((response) => {
        this.items = response?.data;
      });
    },

    async getInstalments(saleId) {
      await http
        .get(`finance/transactions?filter=saleId||eq||${saleId}`)
        .then((transaction) => {
          this.instalments = transaction.data;
        });
    },

    openInvoiceModal(item) {
      this.sale = item;
      this.saleId = item.id;
      this.invoiceModal = true;
    },
    getAccounts() {
      this.loading = true;
      http.get("finance/accounts").then((data) => {
        this.accounts = data.data;
        this.loading = false;
      });
      this.loading = false;
    },

    closeInvoiceModal() {
      this.payed.email = "";
      this.payed.password = "";
      this.payed.accountId = "";
      this.sale = null;
      this.saleId = null;
      this.invoiceModal = false;
    },

    saleInvoice() {
      http.put(`base/sales/${this.saleId}/invoice`, this.payed).then(
        () => {
          this.snackbar.text = "Venda Faturada com Sucesso!";
          this.snackbar.color = "success";
          this.snackbar.opened = true;
          this.invoiceModal = false;
          this.getItems();
        },
        (err) => {
          const { response } = err;
          this.snackbar.text =
            "Falha ao Faturar Venda: " + response?.data?.message;
          this.snackbar.color = "red";
          this.snackbar.opened = true;
          this.invoiceModal = false;
        }
      );
    },

    deleteOpen(itemId) {
      this.saleId = itemId;
      this.deleteDialog = true;
    },

    getItems() {
      this.loading = true;
      http
        .get(
          `base/sales?filter=createdAt||$gte||${this.startDate} 00:00:00&filter=createdAt||$lte||${this.endDate} 23:59:59
          &filter=status||eq||${this.status}`
        )
        .then(
          (data) => {
            this.sales = data.data;
            this.sale = this.sales.length ? this.sales[0] : null;
            if (this.sale) {
              this.getSaleItems(this.sale.id);
              this.getInstalments(this.sale.id);
            } else {
              this.items = [];
              this.instalments = [];
            }
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.snackbar.color = "red";
            this.snackbar.text =
              "Hove um erro ao buscar os registros. Atualize a página por favor";
            this.snackbar.opened = true;
          }
        );
    },

    deleteItem() {
      http.delete(`base/sales/${this.saleId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },

  mounted() {
    this.getItems();
    this.getAccounts();
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}

legend {
  font-size: 12px !important;
  padding: 10px;
}

.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
.short {
  width: 80px;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

